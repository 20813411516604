import React from "react"
import { Link, graphql } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import Nav from "../Components/Nav"
import Footer from "../Components/Footer"
import SEO from "../Components/SEO"

const JobTemplate = ({data}) => {
    const { edges: jobs } = data.allMarkdownRemark;
    const { markdownRemark: job } = data;

    return (
        <main>
          <SEO title={`Funovus Career ${job.frontmatter.title}`} description={job.frontmatter.description}/>
          <Nav />
          <div className="careersSection">
                  <Link to={'../'} ><FontAwesomeIcon icon={faChevronLeft} width="16" /> Back </Link>
                  <div className="careers">
                      <div className="links">
                      {
                          jobs.map(
                              ({node: job}) => 
                              <Link key={job.frontmatter.path} activeClassName="current" to={job.frontmatter.path}>
                                  {job.frontmatter.title}
                              </Link>
                          )
                      }
                      </div>
                      <div className="postings">
                          <div className="hiringText" dangerouslySetInnerHTML={{ __html: job.html }} />
                          {job.frontmatter.applylink ? 
                              <a target="_blank" rel="noreferrer" href={job.frontmatter.applylink}><button className="apply" target="_blank" href={job.frontmatter.applylink}>Apply Here</button></a> 
                              : 
                              <></>
                          }
                      </div>
                  </div>
          </div>
          <Footer />
        </main>
    );
}

export default JobTemplate

export const pageQuery = graphql`
  query JobPostByPath($path: String!) {
    allMarkdownRemark(filter: {
        fileAbsolutePath: {
           regex: "/(jobs)/"
        }
     }, sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          frontmatter {
            title
            path
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt(pruneLength: 250)
      html
      frontmatter {
        title
        path
        type
        description
        applylink
      }
    }
  }
`